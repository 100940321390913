import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mobileThresholdPixels, colors } from '../styledComponents';
import Dots from './v3/Dots';
import Question from './faq/Question';
import mostFrequentIcon from '../../assets/faq/mostFrequentIcon.svg';
import serviceIcon from '../../assets/faq/serviceIcon.svg';
import customerCareIcon from '../../assets/faq/customerCareIcon.svg';
import PopupDeliveryFees from './PopupDeliveryFees';

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 40px;
`;

const MostFrequentContainer = styled.div`
  background-color: ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 74px 0px;
`;

const OtherQuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 74px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 50px 0px;
  }
`;

const Separator = styled.div`
  height: 150px;
  width: 1px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 50px;
  }
`;

const Title1 = styled.h1`
  font-family: Gotham;
  font-weight: 500;
  line-height: 30px;
  font-size: 32px;
  text-align: center;
  color: ${colors.navy};
  margin: 0px 0px 20px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 24px;
    margin: 0px 15px;
  }
`;

const QuestionsContainer = styled.div`
  width: 1166px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    padding: 0px 23px;
    box-sizing: border-box;
  }
`;

const Title2 = styled.h2`
  color: ${colors.navy};
  font-family: Gotham;
  font-size: 26px;
  line-height: 30px;
`;

const Icon = styled.img`
  margin-right: 11px;
`;

class FAQBrand extends React.Component {
  constructor() {
    super();
    this.state = {
      isModalOpened: false,
    };
    this.openPopup = this.openPopup.bind(this);
  }

  openPopup() {
    this.setState({ isModalOpened: true });
  }

  render() {
    const { mostFrequentQuestions, customerCareQuestions, serviceQuestions } = this.props;
    const { isModalOpened } = this.state;
    return (
      <Container>
        {isModalOpened && <PopupDeliveryFees closePopup={() => this.setState({ isModalOpened: false })} />}
        <TitleContainer>
          <Title1>Vous avez besoin d‘aide</Title1>
          <Dots noMargin />
        </TitleContainer>

        <MostFrequentContainer>
          <QuestionsContainer>
            <Row>
              <Icon src={mostFrequentIcon} />
              <Title2>Questions fréquentes</Title2>
            </Row>
            {mostFrequentQuestions.map((question) => (
              <Question
                key={question.question}
                openPopup={this.openPopup}
                question={question}
                alwaysOpened
              />
            ))}
          </QuestionsContainer>
        </MostFrequentContainer>

        <OtherQuestionsContainer>
          <QuestionsContainer>
            <Row>
              <Icon src={serviceIcon} />
              <Title2>Le service</Title2>
            </Row>
            {serviceQuestions.map((question) => (
              <Question
                key={question.question}
                openPopup={this.openPopup}
                question={question}
              />
            ))}
          </QuestionsContainer>

          <Separator />

          <QuestionsContainer>
            <Row>
              <Icon src={customerCareIcon} />
              <Title2>SAV</Title2>
            </Row>
            {customerCareQuestions.map((question) => (
              <Question
                key={question.question}
                openPopup={this.openPopup}
                question={question}
              />
            ))}
          </QuestionsContainer>
        </OtherQuestionsContainer>
      </Container>
    );
  }
}

FAQBrand.propTypes = {
  customerCareQuestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mostFrequentQuestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  serviceQuestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FAQBrand;
