import React from 'react';
import { Container, Content } from '../../components/home/homeStyledComponents';
import MenuBar from '../../components/home/MenuBar';
import FAQBrand from '../../components/home/FAQBrand';
import Footer from '../../components/home/Footer';
import Layout from '../../layouts/index';

// SEZANE
import {
  mostFrequentQuestions as mostFrequentQuestionsSezane,
  customerCareQuestions as customerCareQuestionsSezane,
  serviceQuestions as serviceQuestionsSezane,
} from '../../components/home/faq/sezaneQuestions';

// GALERIES_LAFAYETTE
import {
  mostFrequentQuestions as mostFrequentQuestionsGaleriesLafayette,
  customerCareQuestions as customerCareQuestionsGaleriesLafayette,
  serviceQuestions as serviceQuestionsGaleriesLafayette,
} from '../../components/home/faq/galeriesLafayetteQuestions';

// MADURA
import {
  mostFrequentQuestions as mostFrequentQuestionsMadura,
  customerCareQuestions as customerCareQuestionsMadura,
  serviceQuestions as serviceQuestionsMadura,
} from '../../components/home/faq/maduraQuestions';

// SESSUN
import {
  mostFrequentQuestions as mostFrequentQuestionsSessun,
  customerCareQuestions as customerCareQuestionsSessun,
  serviceQuestions as serviceQuestionsSessun,
} from '../../components/home/faq/sessunQuestions';

// Brand default
import {
  mostFrequentQuestions as mostFrequentQuestionsBrandDefault,
  customerCareQuestions as customerCareQuestionsBrandDefault,
  serviceQuestions as serviceQuestionsBrandDefault,
} from '../../components/home/faq/brandDefaultQuestions';

function renderFAQVersion() {
  switch (process.env.GATSBY_BRAND) {
    case 'SEZANE':
      return (
        <FAQBrand
          mostFrequentQuestions={mostFrequentQuestionsSezane}
          customerCareQuestions={customerCareQuestionsSezane}
          serviceQuestions={serviceQuestionsSezane}
        />
      );
    case 'GALERIES_LAFAYETTE':
      return (
        <FAQBrand
          mostFrequentQuestions={mostFrequentQuestionsGaleriesLafayette}
          customerCareQuestions={customerCareQuestionsGaleriesLafayette}
          serviceQuestions={serviceQuestionsGaleriesLafayette}
        />
      );
    case 'MADURA':
      return (
        <FAQBrand
          mostFrequentQuestions={mostFrequentQuestionsMadura}
          customerCareQuestions={customerCareQuestionsMadura}
          serviceQuestions={serviceQuestionsMadura}
        />
      );
    case 'SESSUN':
      return (
        <FAQBrand
          mostFrequentQuestions={mostFrequentQuestionsSessun}
          customerCareQuestions={customerCareQuestionsSessun}
          serviceQuestions={serviceQuestionsSessun}
        />
      );
    default:
      return (
        <FAQBrand
          mostFrequentQuestions={mostFrequentQuestionsBrandDefault}
          customerCareQuestions={customerCareQuestionsBrandDefault}
          serviceQuestions={serviceQuestionsBrandDefault}
        />
      );
  }
}

class FAQPage extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Layout routeSlug="FAQPage">
        <Container>
          <Content>
            <MenuBar />
            {renderFAQVersion()}
            <Footer />
          </Content>
        </Container>
      </Layout>
    );
  }
}

export default FAQPage;
